import { Storage } from '@capacitor/storage'

const state = () => ({
  trackingAuthorized: 'undecided'
})

const getters = {
  getTrackingAuthorized(state){
    return state.trackingAuthorized
  }
}

const actions = {
  // payload needs to be a string
  async setTrackingAuthorized({ commit }, payload){
    await Storage.set({
     key: 'trackingAuthorized',
     value: payload
    })
    commit('saveTrackingAuthorized', payload)
  },
  async loadTrackingAuthorized({ commit }) {
    const trackingSaved = await Storage.get({ key: 'trackingAuthorized' })
    if (trackingSaved && trackingSaved.value) {
      commit('saveTrackingAuthorized', trackingSaved.value)
    } else {
      commit('saveTrackingAuthorized', 'undecided')
    }
  }
}

const mutations = {
  saveTrackingAuthorized(state, status){
    state.trackingAuthorized = status
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
