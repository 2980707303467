import { createRouter, createWebHistory } from '@ionic/vue-router'
import { Device } from '@capacitor/device'
import { isPlatform } from '@ionic/vue'
import Tabs from '../pages/Tabs.vue'
import store from '../store/index'

// SPECIFIC FOR PWA >>>

let isInvite = window.location.pathname.includes('invite')
if (isInvite) {
  let searchParams = window.location.search // gives '/user_id'
  if (searchParams && searchParams.length > 0) {
    let userId = window.location.search.substring(1)
    store.dispatch('auth/recommendedByUser', {
      data: {
        User: userId
      }
    }).then(
      () => {
        console.log("success")
        // window.location.href = 'https://app.orszagosbortura.hu'
        window.location.assign('https://app.orszagosbortura.hu')
      },
      (error) => {
        console.log('error: ' + error)
        // window.location.assign('http://localhost:8100')
        window.location.assign('https://app.orszagosbortura.hu')
      }
    )
  }
}

let openPage = false
if(window.location.pathname == '/email-ok' || window.location.pathname == '/ujjelszo' || window.location.pathname == '/app/udvozoljuk'){
  openPage = true
}

let nativePlatform = false

function sleep(milliseconds) {
  const date = Date.now();
  let currentDate = null;
  do {
    currentDate = Date.now();
  } while (currentDate - date < milliseconds);
}

// check platform for mobile non tablet
// only if we're not on openPage
if (isPlatform('mobile') && !isPlatform('tablet') && !openPage) {
  checkDevice()
// exclude tablets
} else if (isPlatform('tablet')) {
  window.location.href = 'https://www.orszagosbortura.hu'
  sleep(1000)
}

let device
async function checkDevice() {
  device = await Device.getInfo()
  // ios
  if(device.operatingSystem == 'ios') {
    let iosOk = false
    let supportedIosVersions = ['13','14','15','16','17']
    for (let i in supportedIosVersions) {
      if (device.osVersion.match(supportedIosVersions[i])) {
        iosOk = true
      }
    }
    if (iosOk) {
      nativePlatform = true
      window.location.href = 'https://apps.apple.com/us/app/orszagos-bortura/id1588683275'
      sleep(1000)
    }
  // android
  } else if (device.operatingSystem == 'android') {
    let androidOk = false
    let supportedAndroidVersions = ['5','6','7','8','9','10','11','12','13','14']
    for (let i in supportedAndroidVersions) {
      if (device.osVersion.match(supportedAndroidVersions[i])) {
        androidOk = true
      }
    }
    if (androidOk) {
      nativePlatform = true
      window.location.href = 'https://play.google.com/store/apps/details?id=hu.orszagosbortura.bortura'
      sleep(1000)
    }
  }
}
// <<< SPECIFIC FOR PWA

const routes = [
  {
    path: '/',
    component: Tabs,
    children: [{
        path: 'boraszatok',
        name: 'boraszatok',
        component: () => import('@/pages/Boraszatok.vue'),
        meta: {
          requiredAuth: true,
          redirect: '/app/udvozoljuk'
        }
      },{
        path: 'boraszatok/elso-checkin',
        name: 'elso-checkin',
        component: () => import('@/pages/ElsoCheckin.vue')
      },{
        path: 'terkep',
        name: 'terkep',
        component: () => import('@/pages/Terkep.vue')
      },{
        path: 'checkin',
        name: 'checkin',
        component: () => {
          if (isPlatform('hybrid')) {
            return import('@/pages/CheckinHybrid.vue')
          } else {
            return import('@/pages/CheckinWeb.vue')
          }
        }
      },{
        path: 'checkin/logged-out',
        name: 'checkin-logged-out',
        component: () => import('@/pages/CheckinLoggedOut.vue')
      },{
        path: 'profil',
        name: 'profil',
        component: () => import('@/pages/Profil.vue'),
        meta: {
          requiredAuth: true,
          redirect: '/profil/logged-out'
        }
      },{
        path: 'profil/logged-out',
        name: 'profil-logged-out',
        component: () => import('@/pages/ProfilLoggedOut.vue')
      },{
        path: 'informaciok',
        name: 'informaciok',
        component: () => import('@/pages/Informaciok.vue')
      },{
        path: 'profil/email-konfirmacio',
        name: 'profil-email-konfirmacio',
        component: () => import('@/pages/EmailKonfirmacio.vue')
      },{
        path: 'app/udvozoljuk',
        name: 'udvozoljuk',
        component: () => import('@/pages/Udvozoljuk.vue')
      },{
        path: 'app/confirm',
        name: 'checkin-confirm',
        component: () => import('@/pages/CheckinConfirm.vue')
      },{
        path: 'app/rating-stars',
        name: 'rating-stars',
        component: () => import('@/pages/RatingStars.vue')
      },{
        path: 'app/rating-comment',
        name: 'rating-comment',
        component: () => import('@/pages/RatingComment.vue')
      },{
        path: 'app/rating-thanks',
        name: 'rating-thanks',
        component: () => import('@/pages/RatingThanks.vue')
      }
    ]
  },{
    path: '/intro',
    name: 'intro',
    component: () => import('@/pages/Intro.vue')
  },{
    path: '/boraszat-profil:id',
    name: 'boraszat-profil',
    component: () => import('@/pages/BoraszatProfil.vue')
  },{
    path: '/boraszat-ertekelesek',
    name: 'boraszat-ertekelesek',
    component: () => import('@/pages/BoraszatErtekelesek.vue')
  },{
    path: '/regisztracio',
    name: 'regisztracio',
    component: () => import('@/pages/Regisztracio.vue')
  },{
    path: '/regisztracio/email-konfirmacio',
    name: 'regisztracio-email-konfirmacio',
    component: () => import('@/pages/EmailKonfirmacio.vue')
  },{
    path: '/login',
    name: 'login',
    component: () => import('@/pages/Login.vue')
  },{
    path: '/email-ok',
    name: 'email-ok',
    component: () => import('@/pages/EmailOk.vue')
  },{
    path: '/elvesztettjelszo',
    name: 'elvesztettjelszo',
    component: () => import('@/pages/ElvesztettJelszo.vue')
  },{
    path: '/ujjelszo',
    name: 'ujjelszo',
    component: () => import('@/pages/UjJelszo.vue')
  },{
    path: '/platformerror',
    name: 'platformerror',
    component: () => import('@/pages/PlatformError.vue')
  },{
    path: '/platform',
    name: 'platform',
    component: () => import('@/pages/Platform.vue')
  },{
    path: '/boraszat-ajanlas',
    name: 'boraszat-ajanlas',
    component: () => import('@/pages/BoraszatAjanlas.vue')
  },{
    path: '/platform-ajanlas',
    name: 'platform-ajanlas',
    component: () => import('@/pages/PlatformAjanlas.vue')
  },{
    path: '/ertekeleseim',
    name: 'ertekeleseim',
    component: () => import('@/pages/Ertekeleseim.vue')
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

async function guard(to, from, next, authData) {
  if (!device) {
    device = await Device.getInfo()
  }
  if (to.fullPath == '/platformerror') {
    window.location.href = 'https://www.orszagosbortura.hu'
    // return next()
  // exclude non-ios or non-android devices
  } else if (device.operatingSystem != 'ios' && device.operatingSystem != 'android') {
  // } else if (device.operatingSystem != 'ios' && device.operatingSystem != 'android' && device.operatingSystem != 'mac') {
    // return next({ path: '/platformerror' })
    window.location.href = 'https://www.orszagosbortura.hu'
  } else if (nativePlatform) {
    window.location.href = 'https://www.orszagosbortura.hu'
  } else if (to.fullPath == '/') {
    if (authData && authData.userId) {
      // return next({ path: '/terkep' })
      return next({ path: '/boraszatok' })
    } else {
      return next({ path: '/intro' })
      // return next({ name: 'intro' })
    }
  } else if (to.meta && to.meta.requiredAuth) {
    if (authData && authData.userId) {
      return next()
    } else {
      return next({ path: to.meta.redirect })
    }
  } else {
    return next()
  }
}

router.beforeEach((to, from, next) => {
  // console.log("from = " + from.fullPath + " / to = " + to.fullPath)
  let authData = store.getters['auth/getAuthData']
  if (authData.userId == 0 || authData.userId == "" || authData.token == "" || !authData.token) {
    store.dispatch('auth/loadStorageTokens').then(
      () => {
        authData = store.getters['auth/getAuthData']
        return guard(to, from, next, authData)
      },
      (error) => {
        console.log(error)
        return guard(to, from, next, authData)
      }
    )
  } else {
    return guard(to, from, next, authData)
  }
})

export default router
