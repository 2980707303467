import axios from 'axios'

const state = () => ({
  wineries: [],
  wineregions: [],
  wineryprofiles: [],
  winerystyles: []
})

const getters = {
  getWineries(state){
    return state.wineries
  },
  getWineregions(state){
    return state.wineregions
  },
  getWineryprofiles(state){
    return state.wineryprofiles
  },
  getWinerystyles(state){
    return state.winerystyles
  }
}

const actions = {
  async setWineries({ commit }){
    try {
      const response = await axios.get(process.env.VUE_APP_STRAPI_URI + 'wineries?_sort=NameWithoutAccents:ASC&_limit=400')
      let wineriesFromDb = []
      let wineriesCrude = await response.data

      // only display wineries that have GPS coordinates
      for (let i in wineriesCrude) {
        if(wineriesCrude[i].Addresses && wineriesCrude[i].Addresses.length > 0) {
          let counter = 0
          for (let j in  wineriesCrude[i].Addresses) {
            // only if GPS coordinates are available for all locations
            if(wineriesCrude[i].Addresses[j].x && wineriesCrude[i].Addresses[j].y) {
              counter++
            }
          }
          if(counter == wineriesCrude[i].Addresses.length) {
            // delete wineriesCrude[i].Logo
            wineriesCrude[i].zIndexOffset = 0
            wineriesFromDb.push(wineriesCrude[i])
          }
        }
      }
      commit('saveWineries', wineriesFromDb)
    } catch (error) {
      console.log('An error occurred:', error.response.data.error)
    }
  },
  async setWineregions({ commit }){
    try {
      const response = await axios.get(process.env.VUE_APP_STRAPI_URI + 'wineregions?_sort=Title:ASC')
      commit('saveWineregions', response.data)
    } catch (error) {
      console.log('An error occurred:', error.response.data.error)
    }
  },
  async setWineryprofiles({ commit }){
    try {
      const response = await axios.get(process.env.VUE_APP_STRAPI_URI + 'winery-profiles?_sort=updatedAt:ASC')
      commit('saveWineryprofiles', response.data)
    } catch (error) {
      console.log('An error occurred:', error.response.data.error)
    }
  },
  async setWinerystyles({ commit }){
    try {
      const response = await axios.get(process.env.VUE_APP_STRAPI_URI + 'winery-styles')
      commit('saveWinerystyles', response.data)
    } catch (error) {
      console.log('An error occurred:', error.response.data.error)
    }
  }
}

const mutations = {
  saveWineries(state, status){
    state.wineries = status
  },
  saveWineregions(state, status){
    state.wineregions = status
  },
  saveWineryprofiles(state, status){
    state.wineryprofiles = status
  },
  saveWinerystyles(state, status){
    state.winerystyles = status
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
