<template>
  <ion-page>
    <ion-header mode="ios">

      <ion-segment
        :value="segmentValue"
        @ionChange="onSegmentChange($event)"
        ref="ionSegmentRef"
        mode="ios"
      >
        <ion-segment-button value="0">
          <ion-label>Térkép</ion-label>
        </ion-segment-button>
        <ion-segment-button value="1">
          <ion-label>Lista</ion-label>
        </ion-segment-button>
      </ion-segment>
    </ion-header>

    <ion-content
      fullscreen
      ref="ionContentRef"
      :scrollY="scrollYAuthorized"
    >
    <!-- scrollEvents -->
      <slot> </slot>
    </ion-content>
  </ion-page>
</template>

<script>

import {
  IonPage, IonHeader, IonContent, IonSegment, IonSegmentButton, IonLabel
} from '@ionic/vue'

export default {
  components: {
    IonPage, IonHeader, IonContent, IonSegment, IonSegmentButton, IonLabel
  },
  props: ['segmentValue'],
  watch: {
    segmentValue () {
      if (this.segment) {
        this.segment.value = this.segmentValue
      }
    }
  },
  data () {
    return {
      segment: null,
    }
  },
  computed: {
    scrollYAuthorized () {
      if (this.segmentValue == 1) {
        return true
      } else {
        return false
      }
    }
  },
  mounted () {
    this.segment = this.$refs.ionSegmentRef.$el
  },
  methods: {
    onSegmentChange (ev) {
      this.$refs.ionContentRef.$el.scrollToTop()
      // only call it if the segment change initiatied by ion-segment, not by a slide change
      if(this.segment.value != this.segmentValue) {
        this.$emit('onSegmentChange',ev)
      }
    }
  }
}
</script>

<style scoped>
ion-content{
  --background: #ffffff;
  --padding-top: calc(0 - env(safe-area-inset-top));
}
ion-header{
  height: 0;
}
ion-segment{
  z-index: 10000;
  width: 90%;
  background-color: #f6dbcd;
  margin: 0 auto 0 auto;
  position: relative;
  top: calc(20px +  env(safe-area-inset-top));
}
</style>
