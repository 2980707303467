<template>
  <ion-app>
    <ion-router-outlet></ion-router-outlet>
  </ion-app>
</template>

<script>
import { IonApp, IonRouterOutlet } from '@ionic/vue'
import store from './store/index'
import { Device } from '@capacitor/device'
import { FirebaseAnalytics } from '@capacitor-community/firebase-analytics'
import { Dialog } from '@capacitor/dialog'
import { mapGetters, mapActions } from 'vuex'
import { SplashScreen } from '@capacitor/splash-screen';
import {
  AppTrackingTransparency,
  // AppTrackingStatusResponse,
} from 'capacitor-plugin-app-tracking-transparency'

export default {
  name: 'OBT',
  components: {
    IonApp,
    IonRouterOutlet
  },
  data () {
    return {
      firebaseConfig: {
        apiKey: 'AIzaSyBe9j2v_AB_-_WYQKkCgm_epNR_4o7dQ4U',
        authDomain: 'orszagos-bortura.firebaseapp.com',
        projectId: 'orszagos-bortura',
        storageBucket: 'orszagos-bortura.appspot.com',
        messagingSenderId: '527731021802',
        appId: '1:527731021802:web:51852f3a853a7ac1064493',
        measurementId: 'G-D8K4BR6E16'
      },
      trackingOK: false,
      deviceInfo: null
    }
  },
  watch:{
    $route (to, from){
      console.log('from = ' + from.name + ' / to = ' + to.name)
      let nameToDisplay = 'page-name-undefined'
      if (to.name) {
        nameToDisplay = to.name
      }
      // Firebase set screenName - if tracking permitted + native platform
      if (this.trackingOK == true && this.deviceInfo.platform != 'web') {
        FirebaseAnalytics.setScreenName({
          screenName: 'app/' + nameToDisplay,
          nameOverride: 'app/' + nameToDisplay
        })
      }
    }
  },
  computed: {
    ...mapGetters('privacy', {
      trackingAuthorized: 'getTrackingAuthorized'
    }),
  },

  async created () {
    await SplashScreen.show({
      autoHide: false
    })
  },

  async mounted () {
    this.deviceInfo = await Device.getInfo()
    // only if not on platformerror page
    if (this.$route.path != "/platformerror") {
      if(this.deviceInfo.operatingSystem == 'ios') {
        this.showIosDialog()
      } else {
        await this.loadTrackingAuthorized()
        this.trackingOK = await this.trackingAuthorized

        if (this.trackingOK == 'undecided') {
          this.showCustomTrackingDialog()
        } else if (this.trackingOK == true) {
          this.launchGoogleAnalytics()
        }
      }
    }

    // load wineries data
    await store.dispatch('wineriesapi/setWineries')
    await store.dispatch('wineriesapi/setWineregions')
    await store.dispatch('wineriesapi/setWineryprofiles')
    await store.dispatch('wineriesapi/setWinerystyles')
    console.log('All winery data loaded')
    await SplashScreen.hide()
  },

  methods: {
    ...mapActions('privacy', {
      setTrackingAuthorized: 'setTrackingAuthorized',
      loadTrackingAuthorized: 'loadTrackingAuthorized'
    }),

    async showIosDialog () {
      AppTrackingTransparency.requestPermission()
      // AppTrackingTransparency.getStatus()
        .then((status) => {
          // console.log(status)
          if(status.status == 'authorized') {
            this.trackingOK = true
            this.launchGoogleAnalytics()
          } else {
            this.trackingOK = false
          }
        })
        .catch((error) => {
          console.log("Failed: " + error)
        })
    },

    async showCustomTrackingDialog () {
      const { value } = await Dialog.confirm({
        title: 'Google Analytics',
        message: `A felhasználói élmény javítását végezzük a Google Analytics segítségével.`,
        okButtonTitle: 'Hozzájárulok',
        cancelButtonTitle: 'Elutasítom'
      })
      if (value) {
        this.setTrackingAuthorized('true')
        this.trackingOK = true
        this.launchGoogleAnalytics()
      } else {
        this.setTrackingAuthorized('false')
        this.trackingOK = false
      }
    },

    async launchGoogleAnalytics () {
      // Initialize Firebase - only needed on web
      if (this.deviceInfo.platform === 'web') {
        await FirebaseAnalytics.initializeFirebase(this.firebaseConfig)
      }
      // Launch Firebase
      await FirebaseAnalytics.setCollectionEnabled({
        enabled: true
      })
    }
  }
}
</script>
