<template>
  <ion-page>
    <ion-tabs @ionTabsWillChange="beforeTabChange($event)">
      <ion-router-outlet></ion-router-outlet>
      <ion-tab-bar slot="bottom">

        <ion-tab-button tab="boraszatok" href="/boraszatok">
          <ion-icon
            v-if="activeTab == 'boraszatok'"
            name="wine"
          />
          <ion-icon
            v-else
            name="wine-outline"
          />
          <ion-label>Élményeim</ion-label>
        </ion-tab-button>

        <ion-tab-button tab="terkep" href="/terkep">
          <ion-icon
            v-if="activeTab == 'terkep'"
            name="search"
          />
          <ion-icon
            v-else
            name="search-outline"
          />
          <ion-label>Borászatok</ion-label>
        </ion-tab-button>

        <ion-tab-button tab="checkin" href="/checkin">
          <ion-icon
            v-if="activeTab == 'checkin'"
            name="qr-code"
          />
          <ion-icon
            v-else
            name="qr-code-outline"
          />
          <ion-label>Becsekkolok</ion-label>
        </ion-tab-button>

        <ion-tab-button tab="profil" href="/profil">
          <ion-icon
            v-if="activeTab == 'profil'"
            name="person"
          />
          <ion-icon
            v-else
            name="person-outline"
          />
          <ion-label>Profilom</ion-label>
        </ion-tab-button>

      </ion-tab-bar>
    </ion-tabs>
  </ion-page>
</template>

<script>
import {
  IonTabs, IonTabBar, IonTabButton, IonIcon, IonPage, IonRouterOutlet, IonLabel
} from '@ionic/vue'
import { addIcons } from 'ionicons'
import {
  wine, wineOutline, person, personOutline, qrCode, qrCodeOutline, search, searchOutline
} from 'ionicons/icons'

export default {
  name: 'Tabs',
  components: {
    IonTabs, IonTabBar, IonTabButton, IonIcon, IonPage, IonRouterOutlet, IonLabel
  },
  data () {
    return {
      activeTab: null
    }
  },
  created () {
    addIcons({
      'wine': wine,
      'wine-outline': wineOutline,
      'person': person,
      'person-outline': personOutline,
      'qr-code': qrCode,
      'qr-code-outline': qrCodeOutline,
      'search': search,
      'search-outline': searchOutline
    })
  },
  methods: {
    beforeTabChange (e) {
      this.activeTab = e.tab
    }
  }
}
</script>
